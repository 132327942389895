import React from "react";
import SCHEDULE from "../../assets/proud-week/schedule23-24.png";
import Container from "../../components/Container";
import css from "./ProudWeek.module.css";

function ProudWeek() {
	return (
		<Container withAnimation={true}>
			<h2>Semaine de la fierté en ingénierie</h2>
			<p className={css.center}>
				Voici la programmation de la Semaine de la fierté 2023 qui s&apos;est déroulerée du 20 au 24
				novembre à Polytechnique.
				<br />
				<br />À l&apos;année prochaine 🌈 !
			</p>
			<img className={css.img} src={SCHEDULE} alt="" />
		</Container>
	);
}

export default ProudWeek;
