import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { InstagramEmbed } from "react-social-media-embed";
import LOGO_AEP from "../assets/logos-partners/aep.png";
import LOGO_ENGIQUEERS from "../assets/logos-partners/engiqueers.png";
import NO_TEXT__COLOR_BLACK from "../assets/logos-poly-out/logo_couleur_noir_icone.svg";
import Sponsors from "../components/Sponsors";
import { URL_INSTAGRAM_1, URL_INSTAGRAM_2 } from "../models/data/Links";
import css from "./Home.module.css";

function Home() {
	// On-scroll animations
	const animationVariant = {
		visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
		hidden: { opacity: 0, scale: 0.5 }
	};

	const ulControl = useAnimation();
	const partnersControl = useAnimation();
	const sponsorsControl = useAnimation();
	const [ulRef, ulRefInView] = useInView();
	const [partnersRef, partnersRefInView] = useInView();
	const [sponsorsRef, sponsorsRefInView] = useInView();

	useEffect(() => {
		if (ulRefInView) ulControl.start("visible");
		if (partnersRefInView) partnersControl.start("visible");
		if (sponsorsRefInView) sponsorsControl.start("visible");
	}, [
		ulControl,
		partnersControl,
		sponsorsControl,
		ulRefInView,
		partnersRefInView,
		sponsorsRefInView
	]);

	return (
		<div>
			<div className={css.logoContainer}>
				<img src={NO_TEXT__COLOR_BLACK} alt="Logo Poly-Out" className={css.logo} />
				<b className={css.logoName}>POLY-OUT</b>
			</div>

			<div className={css.summary}>
				<div className={css.containerText}>
					<div className={css.text}>
						<motion.ul ref={ulRef} variants={animationVariant} initial="hidden" animate={ulControl}>
							<li>
								Une communauté de <b className={css.emphasis}>plus de 150 membres</b> 👭🧑‍🤝‍🧑👫👬
							</li>
							<li>
								Une <b className={css.emphasis}>Semaine de la fierté</b> avec une programmation
								haute en couleur 🌈
							</li>
							<li>
								Une <b className={css.emphasis}>soirée réseautage</b>
								<b /> avec une vingtaine d&apos;entreprises en génie participantes ⚙️
							</li>
							<li>
								Un <b className={css.emphasis}>pub arc-en-ciel</b> pour célébrer la communauté
								2SLGBTQIA+ et la diversité 🎉
								<b />
							</li>
							<li>
								Une{" "}
								<b className={css.emphasis}>
									dizaine d’activités de sensibilisation et de cohésion
								</b>{" "}
								tout au long l’année (même en été!) 🎲
							</li>
							<li>
								Un contingent officiel au défilé de <b className={css.emphasis}>Fierté Montréal</b>{" "}
								au mois d&apos;août 🏳️‍🌈🏳️‍⚧️
							</li>
						</motion.ul>
					</div>
				</div>
			</div>

			<motion.div
				className={css.partners}
				ref={partnersRef}
				variants={animationVariant}
				initial="hidden"
				animate={partnersControl}>
				<img src={LOGO_AEP} className={css.aep} alt="Logo AEP" />
				<img src={LOGO_ENGIQUEERS} className={css.engiqueers} alt="Logo Engiqueers Canada" />
			</motion.div>

			<div className={css.live}>
				<h2>Actualités</h2>
				<div className={css.mediaContainer}>
					<div className={css.instagram}>
						<InstagramEmbed url={URL_INSTAGRAM_1.trim()} />
					</div>
					<div className={css.instagram}>
						<InstagramEmbed url={URL_INSTAGRAM_2.trim()} />
					</div>
				</div>
			</div>

			<motion.div
				ref={sponsorsRef}
				variants={animationVariant}
				initial="hidden"
				animate={sponsorsControl}>
				<Sponsors />
				<hr className={css.divider} />
				<p className={css.center}>
					Merci également à&nbsp;
					<Link to="https://www.polymtl.ca/edi/" className={css.link}>
						<u>Équité, diversité et inclusion</u>
					</Link>
					&nbsp;de Polytechnique!
				</p>
			</motion.div>
		</div>
	);
}

export default Home;
