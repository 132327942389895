import React from "react";
import Container from "../components/Container";
import ExcelReader from "../components/MemberDisplay";
import SmallCard from "../components/SmallCard";
import { EColor } from "../constants/COLORS";
import css from "./About.module.css";

function About() {
	return (
		<Container withAnimation>
			<h2 id="le-comite">Le comité</h2>
			<div>
				<p>
					Le comité Poly-Out a pour mission de sensibiliser, éduquer et conscientiser les membres de
					Polytechnique Montréal à la réalité et aux enjeux associés à la diversité sexuelle et de
					genre, à l&apos;inclusion et à l&apos;équité.
				</p>
				<p>
					Poly-Out s&apos;engage à lutter et prévenir la discrimination, l&apos;homophobie la
					transphobie, à favoriser l&apos;intégration des personnes 2SLGBTQIA+* dans le milieu
					étudiant et professionnel et à sensibiliser sur santé mentale et sexuelle de la communauté
					2SLGBTQIA+.
				</p>
				<p>
					Son activité est axé sur l&apos;intégration, le réseautage et la sensibilisation et
					s&apos;adresse à l&apos;ensemble des membres de la communauté étudiante dans un esprit
					d&apos;ouverture et d&apos;inclusion. Le comité favorise l&apos;intégration par des
					activités en collaboration avec les autres comités de Polytechnique, les comités des
					écoles partenaires et le milieu professionnel.
				</p>
				<p>
					Le comité s&apos;engage à garder l&apos;anonymat des membres qui le désirent. Suite au
					dépôt du Plan d&apos;action pour la lutte contre l&apos;homophobie et la transphobie de
					Polytechnique Montréal 2017-2022, Poly-Out chapeautera une multitude d&apos;initiatives et
					d&apos;activités.
				</p>
				<SmallCard withBanner color={EColor.GREEN}>
					<h3>Nos objectifs</h3>
					<ol>
						<li>
							Conscientiser, éduquer et sensibiliser les membres de Polytechnique Montréal à la
							réalité et aux enjeux associés à la diversité sexuelle et de genre, à l&apos;inclusion
							et à l&apos;équité ;
						</li>
						<li>
							Accroître la visibilité de Poly-Out à l&apos;intérieur et à l&apos;extérieur de
							Polytechnique ;
						</li>
						<li>
							Faciliter la communication entre les membres et alliés de la communauté 2SLGBTQIA+.
						</li>
					</ol>
				</SmallCard>
			</div>

			<div className={css.endnote}>
				<small>
					<i>
						*2SLGBTQIA+ est un acronyme pour “two-spirit**, lesbian, gay, bisexual, transgender,
						queer, intersex and asexual” tandis que le “+” représente d&apos;autres façons dont les
						individus expriment leur genre et leur sexualité en dehors de l&apos;hétéronormativité
						et du genre binaire. **Two-spirit : fait référence aux personnes qui s&apos;identifient
						comme ayant à la fois un esprit masculin et un esprit féminin, et est utilisée par
						certains peuples autochtones pour décrire leur identité sexuelle, sexuelle et / ou
						spirituelle.
					</i>
				</small>
			</div>

			<hr className={css.divider} />
			<h2 id="executif">Exécutif 💚</h2>
			<p className={css.center}>
				Survole les photos de notre exécutif pour en connaître davantage!
			</p>
			<ExcelReader></ExcelReader>
		</Container>
	);
}

export default About;
