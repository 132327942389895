import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Home from "./routes/Home";
import Calendar from "./routes/events/Calendar";
import Networking from "./routes/events/Networking";
import ProudWeek from "./routes/events/ProudWeek";
import Layout from "./routes/misc/Layout";
import PageNotFound from "./routes/misc/PageNotFound";
import NameChange from "./routes/resources/NameChange";
import Room from "./routes/resources/Room";
import Services from "./routes/resources/Services";
import Washroom from "./routes/resources/Washroom";

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path="a-propos" element={<About />} />
				<Route path="calendrier" element={<Calendar />} />
				<Route path="local" element={<Room />} />
				<Route path="toilettes" element={<Washroom />} />
				<Route path="changement-prenom" element={<NameChange />} />
				<Route path="services" element={<Services />} />
				<Route path="semaine-fierte" element={<ProudWeek />} />
				<Route path="soiree-reseautage" element={<Networking />} />
				<Route path="contact" element={<Contact />} />
			</Route>
			<Route path="/*" Component={PageNotFound} />
		</Routes>
	);
}

export default App;
