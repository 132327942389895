import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridDensity, GridToolbarQuickFilter } from "@mui/x-data-grid";
import React from "react";
import css from "./TableGrid.module.css";

function TableGrid({
	rows,
	columns,
	pageSize,
	density
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rows: any;
	columns: GridColDef[];
	pageSize: number;
	density: GridDensity;
}) {
	return (
		<Box className={css.bookTable}>
			<DataGrid
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: pageSize
						}
					},
					columns: {
						columnVisibilityModel: {
							id: false
						}
					}
				}}
				slots={{
					toolbar: GridToolbarQuickFilter
				}}
				localeText={{
					toolbarQuickFilterPlaceholder: "Rechercher..."
				}}
				pageSizeOptions={[10]}
				disableRowSelectionOnClick
				disableColumnFilter
				disableColumnMenu
				density={density}
			/>
		</Box>
	);
}

export default TableGrid;
