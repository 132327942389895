import React from "react";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import SmallCard from "../../components/SmallCard";
import { EColor } from "../../constants/COLORS";
import css from "./NameChange.module.css";

function NameChange() {
	return (
		<Container withAnimation={true}>
			<h2>Changement de prénom</h2>

			<SmallCard withBanner={true} color={EColor.RED}>
				<p>
					Il est maintenant possible d&apos;effectuer un changement de prénom au sein du
					registrariat de Polytechnique. Le processus sera légèrement différent selon le cas :
				</p>
			</SmallCard>
			<ol className={css.list}>
				<li>
					Vous avez fait votre changement de prénom officiel auprès du Directeur de l&apos;état
					civil
					<p>
						<p>
							Pour changer le nom ou la mention de sexe dans votre dossier étudiant ainsi que dans
							tous les documents officiels émis par Polytechnique :{" "}
							<Link
								to="https://etudiant.polymtl.ca/etudes/renseignements-generaux/modification-de-nom"
								className={css.link}
								target="_blank">
								<u>Cliquez ici</u>.
							</Link>
						</p>
					</p>
				</li>
				<li>
					Vous n&apos;avez <b>pas</b> fait votre changement de prénom officiel auprès du Directeur
					de l&apos;état civil
					<p>
						<p>
							Pour changer de prénom sur votre adresse courriel, moodle, les documents non-officiels
							et les communications institutionnelles : <br />
							<ol>
								<li>
									Remplir le formulaire{" "}
									<Link
										to="https://etudiant.polymtl.ca/etudes/gestion-administrative/prenom-choisi"
										className={css.link}
										target="_blank">
										<u>disponible ici</u>.
									</Link>
								</li>
								<li>
									À l&apos;aide de votre courriel polymtl.ca, envoyer le formulaire à l&apos;adresse{" "}
									<Link to="mailto:poly-out@aep.polymtl.ca" className={css.link} target="_blank">
										<u>prenomchoisi&shy;@polymtl.ca</u>.
									</Link>
								</li>
							</ol>
						</p>
					</p>
				</li>
			</ol>
			<SmallCard withBanner={true} color={EColor.ORANGE}>
				<p>Il est aussi possible de changer la photo qui figure sur votre carte étudiante :</p>
			</SmallCard>
			<p>
				<p>
					Afin de changer la photo sur votre carte étudiante, il faut réserver une prise de photo
					auprès du service de sûreté institutionnelle.
					<ol>
						<li>
							Aller sur{" "}
							<Link
								to="https://www.polymtl.ca/surete/services"
								className={css.link}
								target="_blank">
								<u>le site de la sureté de Polytechnique</u>.
							</Link>
						</li>
						<li>
							Dans l&apos;onglet Carte d&apos;identité, sélectionner Prise de Photo - Étudiant
							première carte et prendre rendez-vous.
						</li>
						<li>
							Lors de votre rendez-vous, apportez votre attestation pour émettre une nouvelle carte
							étudiante (vous la recevrez en réponse à votre demande de changement de prénom). Vous
							recevrez votre carte étudiante avec votre prénom choisi et photo sur place.
						</li>
					</ol>
				</p>
			</p>
		</Container>
	);
}

export default NameChange;
