import { IBookData } from "../interfaces/IBookData";

export const BOOKS_LIST: IBookData[] = [
	{
		id: 0,
		title: "Famille LGBT - Le guide",
		authors: "Greenbaum, M.",
		editor: "du Remue-ménage",
		year: 2015
	},
	{
		id: 1,
		title: "Pride - Chroniques de la révolution gay",
		authors: "Rémès, E.",
		editor: "La musardine",
		year: 2017
	},
	{
		id: 2,
		title: "Au 5e",
		authors: "Boisvert, MP.",
		editor: "La mèche",
		year: 2017
	},
	{
		id: 3,
		title: "Interpellation(s) - Enjeux de l'écriture au \"tu",
		authors: "Boisclair & Rosso, K.",
		editor: "Nota Bene",
		year: 2018
	},
	{
		id: 4,
		title: "La fin du sida est-elle possible ?",
		authors: "Berdougo, F. & Girard, G.",
		editor: "Textuel",
		year: 2017
	},
	{
		id: 5,
		title: "Homo Inc.orporated - Le triangle et la licorne qui pète",
		authors: "Bourcier, S.",
		editor: "Cambourakis",
		year: 2017
	},
	{
		id: 6,
		title: "En finir avec Eddy Bellegueule",
		authors: "Louis, É.",
		editor: "du Seuil",
		year: 2014
	},
	{
		id: 7,
		title: "Vernon Subutex vol.1",
		authors: "Despentes",
		editor: "Grasset & Fasquelle",
		year: 2015
	},
	{
		id: 8,
		title: "Vernon Subutex vol.2",
		authors: "Despentes",
		editor: "Grasset & Fasquelle",
		year: 2015
	},
	{
		id: 9,
		title: "Vernon Subutex vol.3",
		authors: "Despentes",
		editor: "Grasset & Fasquelle",
		year: 2017
	},
	{
		id: 10,
		title: "Fun Home",
		authors: "Bechdel, A.",
		editor: "Denoël Graphic",
		year: 2013
	},
	{
		id: 11,
		title: "Danseur de Manhattan",
		authors: "Holleran, A.",
		editor: "Belfond",
		year: 2003
	},
	{
		id: 12,
		title: "Guapa, A Novel",
		authors: "Haddad, S.",
		editor: "Other Press New York",
		year: 2016
	},
	{
		id: 13,
		title: "Joli Genre! C'est ta mère qui l'a choisi pour toi ?",
		authors: "Labelle, S.",
		editor: "",
		year: ""
	},
	{
		id: 14,
		title: "Adolescences lesbiennes - De l'invisibilité à la reconnaissance",
		authors: "Lebreton, C.",
		editor: "du Remue-ménage",
		year: 2017
	},
	{
		id: 15,
		title: "Queer, A Graphic History",
		authors: "Barker, M. & Scheel, J.",
		editor: "Icon Books",
		year: 2017
	},
	{
		id: 16,
		title: "Blue is the warmest color",
		authors: "Maroh, J.",
		editor: "Arsenal Pulp Press",
		year: 2013
	},
	{
		id: 17,
		title: "Sunstone vol.1",
		authors: "Sejic, S.",
		editor: "Top Cow Productions",
		year: 2014
	},
	{
		id: 18,
		title: "Sunstone vol.2",
		authors: "Sejic, S.",
		editor: "Top Cow Productions",
		year: 2015
	},
	{
		id: 19,
		title: "The New Joy of Gay Sex",
		authors: "Silverstein, C. & Picano, F.",
		editor: "Harper Collins",
		year: 1992
	},
	{
		id: 20,
		title: "The Joy of Lesbian Sex",
		authors: "Sisley, E. & Harris, B.",
		editor: "Mitchell Beazly Publishers",
		year: 1977
	},
	{
		id: 21,
		title: "Mauvais genre",
		authors: "Cruchaudet, C.",
		editor: "Delcourt",
		year: 2013
	},
	{
		id: 22,
		title: "La lesbienne invisible",
		authors: "Rosemarie, O. & Revel, S.",
		editor: "Delcourt",
		year: 2013
	},
	{
		id: 23,
		title: "Querelles de Roberval",
		authors: "Lambert, K.",
		editor: "Héliotrope",
		year: 2018
	},
	{
		id: 24,
		title: "N'essuie jamais de larmes sans gants",
		authors: "Gaïa editor",
		editor: "",
		year: 2016
	},
	{
		id: 25,
		title: "Lesbiennes de l'immigration",
		authors: "Amari, S.",
		editor: "du Croquand",
		year: 2018
	},
	{
		id: 26,
		title: "La salope éthique",
		authors: "Easton, D. & Hardy, J. W.",
		editor: "Tabous",
		year: 2013
	},
	{
		id: 27,
		title: "Crip Theory, Cultural Signs of Queerness and Disability",
		authors: "McRuer, R.",
		editor: "New York University Press",
		year: 2006
	},
	{
		id: 28,
		title: "LSTW - Lesbian Culture Canada - Ève Salvail",
		authors: "Red Rising Magazine",
		editor: "",
		year: ""
	},
	{
		id: 29,
		title: "LSTW - Lesbian Culture Canada - Lena White",
		authors: "Red Rising Magazine",
		editor: "",
		year: ""
	},
	{
		id: 30,
		title: "LSTW - Lesbian Culture Canada - Tegan & Sara",
		authors: "Red Rising Magazine",
		editor: "",
		year: ""
	},
	{
		id: 31,
		title: "Encyclopédie critique du genre",
		authors: "Rennes, J.",
		editor: "La découverte",
		year: ""
	}
];
