import React, { RefObject, useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import NO_TEXT__ALL_BLACK from "../../assets/logos-poly-out/no_text__all_black.png";
import NO_TEXT__COLOR_WHITE from "../../assets/logos-poly-out/no_text__color_black.png";
import { NAVBAR_ITEMS } from "../../models/data/Navbar-Items-Data";
import { useOutsideClick } from "../custom_hooks/useOutsideClick";
import Navbar from "./Navbar";
import css from "./Navbar-Layout.module.css";

function NavbarLayout() {
	const location = useLocation();

	const [isSidebarActive, setIsSidebarActive] = useState(false);
	const toggleSidebar = () => setIsSidebarActive(!isSidebarActive);
	const closeSidebar = () => setIsSidebarActive(false);

	const navbarRef: RefObject<HTMLDivElement> = useOutsideClick(closeSidebar);

	const [onLogoHover, setOnLogoHover] = useState(false);
	const changeLogoToColor = () => setOnLogoHover(true);
	const changeLogoToBlack = () => setOnLogoHover(false);

	const headerRef = useRef<HTMLHeadElement>(null);
	const resetNavbarDisplayInDesktopMode = () => {
		const element = headerRef?.current;
		if (!element) return;

		const observer = new ResizeObserver(() => {
			if (window.innerWidth > 960) setIsSidebarActive(false);
		});

		observer.observe(element);
		return () => observer.disconnect();
	};

	useEffect(() => {
		resetNavbarDisplayInDesktopMode();
	}, []);

	return (
		<header className={css.container} ref={headerRef}>
			<div className={css.navArea} ref={isSidebarActive ? navbarRef : null}>
				<div
					onMouseEnter={changeLogoToColor}
					onMouseLeave={changeLogoToBlack}
					className={onLogoHover ? css.rotate : ""}>
					{location.pathname == "/" || location.pathname == "/#" ? (
						<Link to="/">
							<img
								src={onLogoHover ? NO_TEXT__COLOR_WHITE : NO_TEXT__ALL_BLACK}
								alt="Logo Poly-Out"
								className={css.logoImg}
							/>
						</Link>
					) : (
						<Link to="/">
							<img src={NO_TEXT__COLOR_WHITE} alt="Logo Poly-Out" className={css.logoImg} />
						</Link>
					)}
				</div>
				<b className={css.logoNameNavbar}>POLY-OUT</b>

				<div
					className={
						css.navbar + " " + (isSidebarActive ? css.active + " " + css.animateFadeIn : "")
					}>
					<span className={`${css.button} ${css.close}`} onClick={closeSidebar}>
						<IoMdClose />
					</span>
					<Navbar items={NAVBAR_ITEMS} isMobile={isSidebarActive} onClickCallback={closeSidebar} />
				</div>
				<span className={`${css.button} ${css.open}`} onClick={toggleSidebar}>
					<GiHamburgerMenu />
				</span>
			</div>
		</header>
	);
}

export default NavbarLayout;
