import { GridColDef } from "@mui/x-data-grid";

export const BOOK_COLUMNS: GridColDef[] = [
	{
		field: "id",
		headerName: "ID",
		width: 50,
		sortable: false,
		hideable: true,
		filterable: false
	},
	{
		field: "title",
		headerName: "Titre",
		width: 450,
		sortable: true,
		hideable: false
	},
	{
		field: "authors",
		headerName: "Auteur-trice",
		width: 150,
		sortable: true,
		hideable: false
	},
	{
		field: "editor",
		headerName: "Édition",
		type: "number",
		width: 200,
		sortable: true,
		hideable: false
	},
	{
		field: "year",
		headerName: "Année",
		width: 75,
		sortable: true,
		hideable: false
	}
];
