import React, { useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { IImageData } from "../models/interfaces/IImageData";
import css from "./Carousel.module.css";

function Carousel({ images }: { images: IImageData[] }) {
	const [imageIndex, setImageIndex] = useState(0);

	const nextImage = () => {
		setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
	};

	const previousImage = () => {
		setImageIndex(imageIndex === 0 ? images.length - 1 : imageIndex - 1);
	};

	return (
		<div className={css.container}>
			<div className={css.carousel}>
				<BsArrowLeftCircleFill
					onClick={previousImage}
					className={`${css.arrow} ${css.arrowLeft}`}
				/>
				{images.map((image, index) => {
					return (
						<img
							src={image.src}
							key={index}
							className={imageIndex === index ? css.img : `${css.img} ${css.imgHidden}`}
						/>
					);
				})}
				<BsArrowRightCircleFill onClick={nextImage} className={`${css.arrow} ${css.arrowRight}`} />
			</div>
		</div>
	);
}

export default Carousel;
