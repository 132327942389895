import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import css from "./Footer.module.css";
import RainbowBanner from "./RainbowBanner";

function Footer() {
	const currentYear: number = new Date().getFullYear();

	return (
		<footer className={css.container}>
			<RainbowBanner />
			<div className={css.logosContainer}>
				<Tooltip title="Facebook" placement="top-start" arrow>
					<Link to="https://www.facebook.com/Poly.Out" className={css.socialMediaLogo}>
						<FaFacebookSquare />
					</Link>
				</Tooltip>

				<Tooltip title="Instagram" placement="top-start" arrow>
					<Link to="https://www.instagram.com/polyout.officiel/" className={css.socialMediaLogo}>
						<FaInstagram />
					</Link>
				</Tooltip>

				<Tooltip title="Linkedin" placement="top-start" arrow>
					<Link to="https://www.linkedin.com/company/poly-out-mtl/" className={css.socialMediaLogo}>
						<FaLinkedin />
					</Link>
				</Tooltip>

				<Tooltip title="Courriel" placement="top-start" arrow>
					<Link to={"mailto:poly-out@aep.polymtl.ca"} className={css.socialMediaLogo}>
						<MdAlternateEmail />
					</Link>
				</Tooltip>
			</div>
			<div className={css.address}>
				Local B-313 - Pavillon principal <br />
				2500 chemin de Polytechnique <br />
				Polytechnique Montréal
			</div>
			<div className={css.copyright}>
				POLY-OUT &copy; {currentYear - 1}-{currentYear}
				<div className={css.authors}>
					Site web par&nbsp;
					<Link to="https://www.linkedin.com/in/mikael-gilbert-160b4a19b/">Mikael Gilbert</Link>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
