export interface IMember {
	id: number;
	photoSource?: string;
	name: string;
	pronouns: string;
	engineering: string;
	educationLevel: EducationLevel;
	year: number;
	role: string;
	description?: string;
	linkedin?: string;
}

export enum EducationLevel {
	Bachelor = "Bachelor",
	Master = "Master",
	Doctorate = "Doctorate"
}
