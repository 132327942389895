import React from "react";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import { URL_LATEST_NETWORKING_PHOTOS } from "../../models/data/Links";
import css from "./Networking.module.css";

function Networking() {
	return (
		<Container withAnimation={true}>
			<h2>Soirée réseautage</h2>
			<p className={css.fillBottomPage}>
				Pour voir les photos de la dernière soirée réseautage,{" "}
				<Link to={URL_LATEST_NETWORKING_PHOTOS} className={css.link}>
					<u>cliquez ici</u>!
				</Link>
			</p>
		</Container>
	);
}

export default Networking;
