import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import NavbarLayout from "../../components/navbar/Navbar-Layout";
import css from "./Layout.module.css";

const Layout = () => {
	return (
		<div>
			<div className={css.navbarContainer}>
				<NavbarLayout />
			</div>
			<div>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
