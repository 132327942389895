import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import Carousel from "../../components/Carousel";
import Container from "../../components/Container";
import SmallCard from "../../components/SmallCard";
import TableGrid from "../../components/TableGrid";
import TileCard from "../../components/TileCard";
import { EColor } from "../../constants/COLORS";
import { BOOKS_LIST } from "../../models/data/Book-Lists";
import { BOOK_COLUMNS } from "../../models/data/BookColumns_GridColDef";
import { ROOM_IMAGES } from "../../models/data/Room-Images-Data";
import { ROOM_ITEMS, merchItems } from "../../models/data/Room-Items-Data";
import { IBookData } from "../../models/interfaces/IBookData";
import { TileCardData } from "../../models/interfaces/ITileCardData";
import css from "./Room.module.css";

function Room() {
	const bookList = BOOKS_LIST;

	const getBooks = () => {
		bookList.map((book: IBookData) => {
			if (book.year == "") {
				book.year = "N/D";
			}
			if (book.editor == "") {
				book.editor = "N/D";
			}
		});
	};

	useEffect(() => {
		getBooks();
	}, []);

	return (
		<Container withAnimation={true}>
			<div className={css.room}>
				<h2 id="local">Local B-313</h2>
				<SmallCard withBanner={true} color={EColor.ORANGE}>
					<div>
						Tu cherches un endroit pour relaxer entre deux cours? Tu souhaites rencontrer de
						nouvelles personnes issues de la communauté et des allié·es? Viens nous voir au B-313!
						Vérifie nos&nbsp;
						<HashLink smooth to="/local#horaire">
							<u>heures de permanence</u>
						</HashLink>
						!
					</div>
				</SmallCard>

				<p>
					Situé au troisième étage du pavillon principal, notre local est identifié comme un
					<b> espace sûr / inclusif </b>ayant pour but de faciliter la communication entre les
					membres et les allié.es de la communauté 2SLGBTQIA+ et leur permettre d&apos;échanger
					ensemble au sujet de leurs expériences de marginalisation.
				</p>

				<Grid container className={css.titleCardsContainer}>
					{ROOM_ITEMS.map((data: TileCardData, index: number) => (
						<Grid item key={index} xs={12} sm={6} md={4} lg={4}>
							<TileCard data={data} />
						</Grid>
					))}
				</Grid>

				<Carousel images={ROOM_IMAGES}></Carousel>

				<hr className={css.divider} />
				<h2 id="horaire">Horaire de permanence</h2>
				<SmallCard withBanner={true} color={EColor.ORANGE}>
					<div>
						<h3>Qu&apos;est-ce qu&apos;un horaire de permanence?</h3>
						Ce sont tout simplement les heures d&apos;ouverture assurées du local! Cela signifique
						qu&apos;un membre de l&apos;exécutif sera présent afin de maintenir le local et
						d&apos;accueillir les étudiant·es.
					</div>
				</SmallCard>

				<p>Non disponible</p>

				<p>
					Tu peux tout de même venir en dehors de ces heures. Toutefois, il est possible que le
					local soit fermé.
				</p>
				<p>
					<small>
						<i>
							Note: l&apos;horaire change chaque trimestre selon les disponbilités de nos membres.
						</i>
					</small>
				</p>

				<hr className={css.divider} />
				<h2 id="livres">Livres 📚</h2>
				<p>
					Nous possédons une modeste bibliothèque à thématique 2SLGBTQIA+. Jette un coup d&apos;oeil
					à notre collection ici!
				</p>

				{bookList.length > 0 ? (
					<TableGrid
						rows={bookList}
						columns={BOOK_COLUMNS}
						pageSize={10}
						density="compact"></TableGrid>
				) : (
					<p className={css.emptyPage}>À venir</p>
				)}

				<hr className={css.divider} />
				<h2 id="articles-affirmation">Articles d&apos;affirmation de genre</h2>
				<p>
					Nous pouvons commander de façon <b>anonyme</b> des articles d&apos;
					<b>affirmation de genre ou tout autre article promouvant la santé sexuelle</b>.&nbsp;
					<HashLink to="/contact">Écris-nous</HashLink> pour plus d&apos;informations!
				</p>

				<hr className={css.divider} />
				<h2 id="merch">Articles promotionnels</h2>
				<p>
					Nous gardons en stock des articles promotionnels généraux et provenant de nos événements
					antérieurs au local. N&apos;hésite pas à demander à un membre de l&apos;exécutif si tu
					souhaites t&apos;en procurer!
				</p>
				<Grid container className={css.titleCardsContainer}>
					{merchItems.map((data: TileCardData, index: number) => (
						<Grid item key={index} xs={12} sm={6} md={4} lg={4}>
							<TileCard data={data} />
						</Grid>
					))}
				</Grid>
			</div>
		</Container>
	);
}

export default Room;
