import React from "react";
import css from "./ButtonText.module.css";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
	text: string;
	withAccentColor: boolean;
}

function ButtonText({ text, withAccentColor, ...rest }: Props) {
	return (
		<button className={`${css.button} ${withAccentColor && css.accent}`} {...rest}>
			{text}
		</button>
	);
}

export default ButtonText;
