import React from "react";
import { TileCardData } from "../models/interfaces/ITileCardData";
import css from "./TileCard.module.css";

function TileCard({ data }: { data: TileCardData }) {
	return (
		<div className={css.tile}>
			<div className={css.icon}>{data.icon}</div>
			<div className={css.text}>{data.text}</div>
		</div>
	);
}

export default TileCard;
