import axios from "axios";
import React, { ChangeEvent, useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { COLORS_MAP, EColor } from "../constants/COLORS";
import { NO_CONTENT } from "../constants/RESPONSE_STATUS";
import { MEMBERS_URL } from "../constants/SERVER_API_URLS";
import { EducationLevel, IMember } from "../models/interfaces/IMember";
import ButtonIcon from "./ButtonIcon";
import ButtonText from "./ButtonText";
import css from "./MemberCard.module.css";

function MemberCard({
	member,
	color,
	editable,
	changeCallback
}: {
	member: IMember;
	color: EColor;
	editable: boolean;
	changeCallback?: () => void;
}) {
	const [onHover, setOnHover] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [toDelete, setToDelete] = useState(false);
	const [updatedMember, setUpdatedMember] = useState<IMember>({
		id: member.id,
		photoSource: member.photoSource,
		name: member.name,
		pronouns: member.pronouns,
		engineering: member.engineering,
		educationLevel: member.educationLevel,
		year: member.year,
		role: member.role,
		linkedin: member.linkedin,
		description: member.description
	});

	const handleOnChange = (
		event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
	) => {
		setUpdatedMember({
			...updatedMember,
			[event.target.name]: event.target.value
		});
	};

	const handleOnChangeEducationLevel = (event: ChangeEvent<HTMLInputElement>) => {
		setUpdatedMember({
			...updatedMember,
			educationLevel: event.target.value as EducationLevel
		});
	};

	const handleEdit = () => setIsEditing(true);
	const handleDelete = () => setToDelete(true);
	const handleConfirmDelete = () => deleteMember();
	const handleCancelDelete = () => setToDelete(false);

	const handleSave = () => {
		setIsEditing(false);
		updateMember(updatedMember);
	};

	const handleCancel = () => setIsEditing(false);
	const deleteMember = () => {
		axios
			.delete(`${MEMBERS_URL}/${member.id}`)
			.then(response => {
				if (response.status == NO_CONTENT) {
					changeCallback ? changeCallback() : "";
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const updateMember = (memberToUpdate: IMember) => {
		axios
			.put(MEMBERS_URL, memberToUpdate)
			.then(response => {
				if (response.status == NO_CONTENT) {
					changeCallback ? changeCallback() : "";
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const educationSuffixes = {
		[EducationLevel.Bachelor]: "",
		[EducationLevel.Master]: "(Maitrise)",
		[EducationLevel.Doctorate]: "(Doctorat)"
	};

	return (
		<React.Fragment>
			{isEditing ? (
				<div className={css.formContainer}>
					<form className={css.form}>
						<label htmlFor="name">Nom :</label>
						<input name="name" type="text" placeholder={member.name} onChange={handleOnChange} />
						<label htmlFor="pronouns">Pronoms :</label>
						<input
							name="pronouns"
							type="text"
							placeholder={member.pronouns}
							onChange={handleOnChange}
						/>
						<label htmlFor="engineering">Génie :</label>
						<input
							name="engineering"
							type="text"
							placeholder={member.engineering}
							onChange={handleOnChange}
						/>
						<label htmlFor="educationLevel">Maîtrise?</label>
						<input name="educationLevel" type="text" onChange={handleOnChangeEducationLevel} />
						<label htmlFor="year">Année :</label>
						<input
							name="year"
							type="text"
							placeholder={member.year.toString()}
							onChange={handleOnChange}
						/>
						<label htmlFor="role">Rôle :</label>
						<input name="role" type="text" placeholder={member.role} onChange={handleOnChange} />
						<label htmlFor="linkedin">LinkedIn</label>
						<input
							name="linkedin"
							type="text"
							placeholder={member.linkedin}
							onChange={handleOnChange}
						/>
						<label htmlFor="description">Description</label>
						<textarea
							name="description"
							placeholder={member.description}
							onChange={handleOnChange}
							rows={10}
						/>
						{editable && (
							<div className={css.buttonsContainer}>
								<ButtonText text={"Sauvegarder"} withAccentColor={true} onClick={handleSave} />
								<ButtonText text={"Annuler"} withAccentColor={false} onClick={handleCancel} />
							</div>
						)}
					</form>
				</div>
			) : (
				<div
					className={css.container}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}>
					<img src={member.photoSource} alt={"Photo " + member.name} />
					<h4>{member.name}</h4>
					<h5>{member.role}</h5>
					<h5>{member.pronouns}</h5>
					<h6>
						{member.engineering} - {member.year}
						{member.year == 1 ? "ère" : "e"} année {educationSuffixes[member.educationLevel]}
					</h6>
					<div className={css.linkedin}>
						{member.linkedin && (
							<Link to={member.linkedin}>
								<FaLinkedin />
							</Link>
						)}
					</div>

					{!editable && member.description && (
						<div
							className={onHover ? css.visible : css.hidden}
							style={{ backgroundColor: COLORS_MAP.get(color) }}>
							{member.description}
						</div>
					)}

					{editable && (
						<div className={css.descriptionPreview}>
							{" "}
							<hr />
							{member.description}
						</div>
					)}

					{editable && !toDelete && (
						<React.Fragment>
							<ButtonIcon icon={<MdModeEdit />} onClick={handleEdit} />
							<ButtonIcon icon={<MdDelete />} onClick={handleDelete} />
						</React.Fragment>
					)}

					{editable && toDelete && (
						<React.Fragment>
							<div className={css.alert}>Confirmer la suppression ?</div>
							<ButtonText text="Oui" withAccentColor={false} onClick={handleConfirmDelete} />
							<ButtonText text="Non" withAccentColor={true} onClick={handleCancelDelete} />
						</React.Fragment>
					)}
				</div>
			)}
		</React.Fragment>
	);
}

export default MemberCard;
