import * as XLSX from "xlsx";
import { EducationLevel, IMember } from "../models/interfaces/IMember";

export const excelParser = (arrayBuffer: ArrayBuffer): Promise<IMember[]> => {
	return new Promise((resolve, reject) => {
		try {
			const workbook = XLSX.read(arrayBuffer, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const members: IMember[] = jsonData.map((row: any) => ({
				id: row["ID"],
				photoSource: row["Photo Source"],
				name: row["Name"],
				pronouns: row["Pronouns"],
				engineering: row["Engineering"],
				educationLevel: row.EducationLevel as EducationLevel,
				year: parseInt(row["Year"], 10),
				role: row["Role"],
				description: row["Description"],
				linkedin: row["LinkedIn"]
			}));
			resolve(members);
		} catch (error) {
			reject(error);
		}
	});
};
