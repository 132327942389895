import React from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import SmallCard from "../components/SmallCard";
import { EColor } from "../constants/COLORS";
import css from "./Contact.module.css";

function Contact() {
	return (
		<Container withAnimation={true}>
			<h2>Contact</h2>
			<div className={css.container}>
				<SmallCard withBanner={true} color={EColor.BLUE}>
					<h3>Réseaux sociaux</h3>
					<div className={css.group}>
						<Link to="https://www.facebook.com/Poly.Out">
							<FaFacebookSquare />
							<label>Poly.Out</label>
						</Link>
					</div>
					<div className={css.group}>
						<Link to="https://www.instagram.com/polyout.officiel/">
							<FaInstagram />
							<label>polyout.officiel</label>
						</Link>
					</div>
					<div className={css.group}>
						<Link
							to="https://www.linkedin.com/company/poly-out-mtl/"
							className={css.socialMediaLogo}>
							<FaLinkedin />
							<label>poly-out-mtl</label>
						</Link>
					</div>
					<div className={css.group}>
						<Link to={"mailto:poly-out@aep.polymtl.ca"}>
							<MdAlternateEmail />
							<label>poly-out@aep.polymtl.ca</label>
						</Link>
					</div>
				</SmallCard>

				<SmallCard withBanner={true} color={EColor.BLUE}>
					<h3> Adresse</h3>
					<div>
						Comité POLY-OUT
						<br />
						Association étudiante de Polytechnique <br />
						Local B-313 - Pavillon principal <br />
						2500 chemin de Polytechnique <br />
						Polytechnique Montréal
					</div>
				</SmallCard>
			</div>

			<p>N&apos;hésitez pas à nous écrire si :</p>
			<ul>
				<li>
					vous êtes un·e futur·e étudiant·e ou un·e étudiant·e actuel·le et avez des{" "}
					<b>questions</b> sur notre comité, nos événements ou nos services ;
				</li>
				<li>
					vous souhaitez rejoindre notre <b>groupe privé Facebook</b> ;
				</li>
				<li>
					vous aimeriez vous <b>impliquer</b> au sein de l&apos;exécutif ou en tant que bénévole ;
				</li>
				<li>
					vous avez besoin d&apos;<b>accompagnement</b> pour le processus de changement de prénom ou
					avez tout autre <b>demande</b> (p.ex. accomodations lors d&apos;événements, inscriptions,
					articles d&apos;affirmations de genre, etc.) ;
				</li>
				<li>
					vous êtes un comité étudiant de Polytechnique ou d&apos;une autre université et souhaitez
					&nbsp;<b>collaborer</b> pour organiser des événements à saveur LGBTQIA2+ et promouvant
					l&apos;équité, la diversité et l&apos;inclusion ;
				</li>
				<li>
					vous êtes une entreprise souhaitant en connaître davantage sur notre{" "}
					<b>plan de commandites</b> ;
				</li>
				<li>
					vous avez des commentaires ou des <b>suggestions</b> d&apos;activités ;
				</li>
				<li>
					vous souhaitez simplement nous <b>saluer</b>! 😀
				</li>
			</ul>
			<p>Dans tous les cas, nous serons ravi·es de vous répondre!</p>
		</Container>
	);
}

export default Contact;
