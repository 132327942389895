import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { EColor } from "../constants/COLORS";
import { IMember } from "../models/interfaces/IMember";
import { excelParser } from "../services/excelParser";
import MemberCard from "./MemberCard";

const ExcelReader = () => {
	const [members, setMembers] = useState<IMember[]>([]);

	useEffect(() => {
		const fetchExcelFile = async () => {
			try {
				const response = await fetch("members.xlsx");
				const arrayBuffer = await response.arrayBuffer();
				const parsedMembers = await excelParser(arrayBuffer);
				setMembers(parsedMembers);
			} catch (error) {
				console.error("Error loading or parsing the file:", error);
			}
		};

		fetchExcelFile();
	}, []);

	return (
		<Grid container spacing={2} className="membersContainer">
			{members.map((value: IMember, index: number) => (
				<Grid item key={index} xs={12} sm={6} md={4} lg={4}>
					<MemberCard member={value} color={EColor.GREEN} editable={false} />
				</Grid>
			))}
		</Grid>
	);
};

export default ExcelReader;
