import React from "react";
import LOGO_BNC from "../assets/logos-sponsors/bnc.png";
import LOGO_FERIQUE from "../assets/logos-sponsors/ferique.png";
import LOGO_MORGAN from "../assets/logos-sponsors/morganstanley.png";
import LOGO_UBISOFT from "../assets/logos-sponsors/ubisoft.png";
import css from "./Sponsors.module.css";

function Sponsors() {
	return (
		<div className={css.container}>
			<h2 className={css.center + " " + css.full_width}>Commanditaires & Partenaires</h2>
			<h3>Diamant</h3>
			<p className={css.center}>
				Partenaire principal de la <b>Semaine de la fierté 2023-2024</b>
			</p>
			<img src={LOGO_UBISOFT} className={css.ubisoft + " " + css.diamond} alt="Logo Ubisoft" />
			<h3>Argent</h3>
			<img src={LOGO_BNC} className={css.bnc + " " + css.silver} alt="Logo BNC" />
			<h3>Bronze</h3>
			<img src={LOGO_FERIQUE} className={css.ferique + " " + css.bronze} alt="Logo Férique" />
			<img
				src={LOGO_MORGAN}
				className={css.morganstanley + " " + css.bronze}
				alt="Logo Morgan Stanley"
			/>
		</div>
	);
}

export default Sponsors;
