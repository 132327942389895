import React, { useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { INavbarItem } from "../../models/interfaces/INavbarItem";
import NavbarDropdown from "./Navbar-Dropdown";
import css from "./Navbar-Items.module.css";

function NavbarItems({ item, depthLevel }: { item: INavbarItem; depthLevel: number }) {
	const [dropdown, setDropdown] = useState(false);

	const openDropdown = () => {
		setDropdown(true);
	};

	const closeDropdown = () => {
		setDropdown(false);
	};

	return (
		<div className={css.navbarBarItem}>
			<li className={css.menuItems} onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
				{item.subitems && item.path ? (
					<>
						<HashLink smooth to={item.path}>
							<button type="button" onClick={() => setDropdown(true)}>
								{item.name + "  "}
								{depthLevel > 0 ? <FaCaretRight /> : <span className={css.arrow} />}
							</button>
						</HashLink>
						<NavbarDropdown item={item.subitems} dropdown={dropdown} depthLevel={depthLevel} />
					</>
				) : (
					<HashLink smooth to={item.path}>
						<button type="button">{item.name}</button>
					</HashLink>
				)}
			</li>
		</div>
	);
}

export default NavbarItems;
