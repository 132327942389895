import React from "react";
import Container from "../../components/Container";

function Washroom() {
	return (
		<Container withAnimation={true}>
			<h2>Toilettes non genrées</h2>
			<p>
				Polytechnique dispose de quelques toilettes non genrées au pavillon Lassonde et au pavillon
				principal. Voici celles actuellement disponibles :
			</p>
			<h3>Pavillon Lassonde</h3>
			<p>
				Horaire 7h30 à 18h00 sans carte d&apos;accès. Par la suite, avec carte d&apos;accès du
				département.
			</p>
			<ul>
				<li>
					M-3213
					<p>À partir de 18h: porte d&apos;accès M-3200</p>
				</li>
				<li>
					M-4218
					<p>À partir de 18h: porte d&apos;accès M-4200</p>
				</li>
				<li>
					M-5203
					<p>À partir de 18h: porte d&apos;accès M5400-A</p>
				</li>
				<li>
					M-6202
					<p>À partir de 18h: porte d&apos;accès M-6300</p>
				</li>
			</ul>
			<h3>Pavillon principal</h3>
			<ul>
				<li>C-107</li>
				<li>A-436</li>
				<li>A-686</li>
			</ul>
			<p>Si vous en découvrez de nouvelles, écrivez-nous et nous mettrons à jour la liste!</p>
		</Container>
	);
}

export default Washroom;
