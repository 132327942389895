import React from "react";
import { INavbarItem } from "../../models/interfaces/INavbarItem";
import NavbarItems from "./Navbar-Items";
import NavbarItemsMobile from "./Navbar-Items-Mobile";
import css from "./Navbar.module.css";

function Navbar({
	items,
	isMobile,
	onClickCallback
}: {
	items: INavbarItem[];
	isMobile: boolean;
	onClickCallback: () => void;
}) {
	const depthLevel = 0;
	return (
		<React.Fragment>
			{isMobile ? (
				<nav className={css.mobileContainer}>
					<NavbarItemsMobile items={items} onClickCallback={onClickCallback} />
				</nav>
			) : (
				<nav>
					<ul className={css.desktopContainer}>
						{items.map((item: INavbarItem, index: number) => (
							<NavbarItems item={item} depthLevel={depthLevel} key={index} />
						))}
					</ul>
				</nav>
			)}
		</React.Fragment>
	);
}

export default Navbar;
