import React from "react";
import css from "./Container.module.css";

function Container({
	children,
	withAnimation
}: {
	children?: JSX.Element | JSX.Element[];
	withAnimation: boolean;
}) {
	return <div className={`${css.container} ${withAnimation ? css.animate : ""}`}>{children}</div>;
}

export default Container;
