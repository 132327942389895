import React from "react";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import css from "./Services.module.css";

function Services() {
	return (
		<div className={css.services}>
			<Container withAnimation={true}>
				<h2>Services et organismes</h2>
				<h3>À l&apos;interne</h3>
				<ul>
					<li>
						<Link to="https://etudiant.polymtl.ca/sep/" className={css.link}>
							<u>Services aux étudiant·es de Polytechnique (SEP)</u>
						</Link>
						<ul>
							<li>Soutien psychologique</li>
							<li>Soutien aux étudiantes en situation de handicap</li>
							<li>Service-conseil en immigration et interculturel</li>
							<li>Programmes études-travail</li>
							<li>Tutorat, mentorat et soutien par les pairs</li>
							<li>Et plus encore!</li>
						</ul>
					</li>
					<li>
						<Link to="https://vieetudiante.umontreal.ca/sante-bien-etre" className={css.link}>
							<u>
								Clinique médicale et de soins infirmiers et consultation en psychologie (Université
								de Montréal)
							</u>
						</Link>
					</li>
				</ul>
				<h3>À l&apos;externe</h3>
				<h4>Comités étudiants</h4>
				<ul>
					<li>L&apos;Intégrale - École de technologie supérieure</li>
					<li>Queer McGill - McGill University</li>
					<li>Queer Concordia - Concordia University</li>
					<li>L&apos;Alternative - Université de Montréal</li>
					<li>Groupe Action Trans (GATUM) - Université de Montréal</li>
				</ul>
				<h4>Services et organismes</h4>
				<ul>
					<li>Interligne</li>
					<li>GRIS Montréal</li>
					<li>Coalition des familles LGBT+</li>
					<li>À Deux Mains / Head & Hands</li>
					<li>Jeunesse Lambda</li>
					<li>Alterhéros</li>
					<li>Projet 10</li>
					<li>HELEM</li>
					<li>L&apos;Astérisk*</li>
					<li>RÉZO</li>
					<li>Centre communautaire LGBTQ+ de Montréal (CCLGBTQ)</li>
				</ul>
			</Container>
		</div>
	);
}

export default Services;
