import React from "react";
import { COLORS_MAP, EColor } from "../constants/COLORS";
import css from "./SmallCard.module.css";

function SmallCard({
	children,
	withBanner,
	color
}: {
	children?: JSX.Element | JSX.Element[];
	withBanner: boolean;
	color: EColor;
}) {
	return (
		<div className={css.infosContainer}>
			<div
				className={withBanner ? css.leftBanner : ""}
				style={{ backgroundColor: COLORS_MAP.get(color) }}></div>
			<div className={css.children}>{children}</div>
		</div>
	);
}

export default SmallCard;
